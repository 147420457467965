import React from "react";
import { BenefitLogo } from "../../../assets/Icons";

export const March8Header = ({ companyInfo }) => {
  return (
    <div className="custom-budget__header">
      <span className="custom-budget__company-logo">
        <BenefitLogo color="#1568bf" />
      </span>
      <h3>Srećan 8. mart!</h3>
    </div>
  );
};
