import React from "react";
import { March8Header } from "./March8Header";
import { March8Body } from "./March8Body";
import March8Styles from "./March8Styles";

export const March8 = ({ content, data }) => {
  const showContent = (content, data) => {
    const Content = {
      header: data => <March8Header companyInfo={data} />,
      body: data => <March8Body data={data} />,
      footer: () => <>Footer</>
    };

    return Content[content](data);
  };

  return <March8Styles>{showContent(content, data)}</March8Styles>;
};
