import React, { useEffect, useRef } from "react";
import TreeAnimationStyles from "./TreeAnimationStyles";
import { BenefitLogo } from "../../assets/Icons";

const SnowAnimation = () => {
  // const snowContainerRef = useRef(null);

  // useEffect(() => {
  //   const snowContainer = snowContainerRef.current;
  //   if (!snowContainer) return;

  //   const particlesPerThousandPixels = 0.1;
  //   const fallSpeed = 1.25;
  //   const pauseWhenNotActive = true;
  //   const maxSnowflakes = 200;
  //   const snowflakes = [];
  //   let snowflakeInterval;
  //   let isTabActive = true;

  //   const resetSnowflake = snowflake => {
  //     const size = Math.random() * 5 + 1;
  //     const viewportWidth = window.innerWidth - size; // Adjust for snowflake size
  //     const viewportHeight = window.innerHeight;

  //     snowflake.style.width = `${size}px`;
  //     snowflake.style.height = `${size}px`;
  //     snowflake.style.left = `${Math.random() * viewportWidth}px`; // Constrain within viewport width
  //     snowflake.style.top = `-${size}px`;

  //     const animationDuration = (Math.random() * 3 + 2) / fallSpeed;
  //     snowflake.style.animationDuration = `${animationDuration}s`;
  //     snowflake.style.animationTimingFunction = "linear";
  //     snowflake.style.animationName =
  //       Math.random() < 0.5 ? "fall" : "diagonal-fall";

  //     setTimeout(() => {
  //       if (parseInt(snowflake.style.top, 10) < viewportHeight) {
  //         resetSnowflake(snowflake);
  //       } else {
  //         snowflake.remove();
  //         const index = snowflakes.indexOf(snowflake);
  //         if (index > -1) snowflakes.splice(index, 1);
  //       }
  //     }, animationDuration * 1000);
  //   };

  //   const createSnowflake = () => {
  //     if (snowflakes.length < maxSnowflakes) {
  //       const snowflake = document.createElement("div");
  //       snowflake.classList.add("snowflake");
  //       snowflakes.push(snowflake);
  //       snowContainer.appendChild(snowflake);
  //       resetSnowflake(snowflake);
  //     }
  //   };

  //   const generateSnowflakes = () => {
  //     const numberOfParticles =
  //       Math.ceil((window.innerWidth * window.innerHeight) / 1000) *
  //       particlesPerThousandPixels;
  //     const interval = 5000 / numberOfParticles;

  //     clearInterval(snowflakeInterval);
  //     snowflakeInterval = setInterval(() => {
  //       if (isTabActive && snowflakes.length < maxSnowflakes) {
  //         requestAnimationFrame(createSnowflake);
  //       }
  //     }, interval);
  //   };

  //   const handleVisibilityChange = () => {
  //     if (!pauseWhenNotActive) return;

  //     isTabActive = !document.hidden;
  //     if (isTabActive) {
  //       generateSnowflakes();
  //     } else {
  //       clearInterval(snowflakeInterval);
  //     }
  //   };

  //   generateSnowflakes();

  //   window.addEventListener("resize", () => {
  //     clearInterval(snowflakeInterval);
  //     setTimeout(generateSnowflakes, 1000);
  //   });

  //   document.addEventListener("visibilitychange", handleVisibilityChange);

  //   return () => {
  //     clearInterval(snowflakeInterval);
  //     document.removeEventListener("visibilitychange", handleVisibilityChange);
  //     window.removeEventListener("resize", () => {
  //       clearInterval(snowflakeInterval);
  //       setTimeout(generateSnowflakes, 1000);
  //     });
  //   };
  // }, []);

  return (
    <TreeAnimationStyles>
      {/* <div className="snow-container" ref={snowContainerRef}></div> */}

      <BenefitLogo color="#ffffff" />
    </TreeAnimationStyles>
  );
};

export default SnowAnimation;
