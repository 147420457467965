import styled from "styled-components";

const CustomBudgetsStyles = styled.div`
  min-width: 50vw;
  min-height: 50vh;
  padding: 0;
  max-height: 90vh;

  .custom-budget {
    padding: 20px;
    display: flex;
    flex-direction: column;
  }

  .custom-budget__header {
    height: 50px;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #757575;
  }

  .custom-budget__company-logo {
    border-radius: 50%;
  }
`;

export default CustomBudgetsStyles;
