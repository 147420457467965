import React, { useCallback, useEffect, useState } from "react";

import { March8Cards } from "./March8Cards";
import { FlagCurrency } from "../../../assets/Icons";
import { useSelector } from "react-redux";
import { apiService } from "../../../services/api/api.service";
import { toast } from "react-toastify";

export const March8Body = ({ data }) => {
  const { customBudgetData, getCustomBudgetInfo, shouldShowMorePerks } = data;

  const { id: userId, vocativeName, firstName } = useSelector(
    state => state.app.user
  );

  const [budgetData, setBudgetData] = useState({
    totalBudget: 0,
    spentBudget: 0
  });
  const [selectedDataBudget, setSelectedDataBudget] = useState([]);

  const staticCardData = [
    {
      id: 1,
      title: "Lilly poklon vaučer",
      description: `Kompanija Benefiti vam poklanja Lily poklon vaučer u vrednosti do ${budgetData.totalBudget} dinara.`,
      price: 4000,
      category: "Poklon za 8. mart",
      image:
        "https://gazfduoumclmczxravnt.supabase.co/storage/v1/object/public/platform/localhost/lily-logo.png",
      location: "Remote"
    },
    {
      id: 2,
      title: "Jasmin poklon vaučer",
      description: `Kompanija Benefiti vam poklanja Jasmin poklon vaučer u vrednosti do ${budgetData.totalBudget} dinara.`,
      price: 4000,
      category: "Poklon za 8. mart",
      image:
        "https://gazfduoumclmczxravnt.supabase.co/storage/v1/object/public/platform/localhost/jasmin-logo.png",
      location: "Remote"
    },
    {
      id: 3,
      title: "DM poklon vaučer",
      description: `Kompanija Benefiti vam poklanja DM poklon vaučer u vrednosti do ${budgetData.totalBudget} dinara.`,
      price: 4000,
      category: "Poklon za 8. mart",
      image:
        "https://gazfduoumclmczxravnt.supabase.co/storage/v1/object/public/platform/localhost/dm-logo.png",
      location: "Remote"
    }
  ];

  const morePerksStaticData = [
    {
      id: 4,
      title: "Mali Proizvođači poklon paket 'Liker od maline'",
      description: `Kompanija Benefiti vam poklanja 'Mali Proizvođači poklon paket Liker od maline'.`,
      price: 3420,
      category: "Poklon za 8. mart",
      image:
        "https://gazfduoumclmczxravnt.supabase.co/storage/v1/object/public/platform/localhost/mp-malina.png",
      location: "Remote"
    },
    {
      id: 5,
      title: "Mali Proizvođači poklon paket 'Orahovača'",
      description: `Kompanija Benefiti vam poklanja 'Mali Proizvođači poklon paket Orahovača'.`,
      price: 3970,
      category: "Poklon za 8. mart",
      image:
        "https://gazfduoumclmczxravnt.supabase.co/storage/v1/object/public/platform/localhost/pm-orahovaca.png",
      location: "Remote"
    }
  ];

  const subscribeToCustomBenefit = async payload => {
    try {
      const response = await apiService.post(
        "/custom-budgets/subscribe",
        payload
      );
      await getCustomBudgetInfo();

      toast.success(response.data.message);
    } catch (error) {
      toast.error(
        error.errorMessage || "Nemate dovoljno sredstava za ovu transakciju."
      );
      console.log(error, "Unable to subscribeToCustomBenefit");
    }
  };

  const handleSelect = useCallback(
    data => {
      const payload = {
        userId: userId,
        price: data.price,
        providerId: data.cardId,
        providerDescription: data.cardDescription,
        customProvider: data.customProvider
      };

      subscribeToCustomBenefit(payload);
    },
    [selectedDataBudget]
  );

  useEffect(() => {
    const totalBudget = customBudgetData[0].totalBudget;

    const disableIds = customBudgetData
      .map(item => item.providerId)
      .some(item => item > 3);

    const payload = {
      totalBudget: totalBudget,
      spentBudget: disableIds
        ? totalBudget
        : customBudgetData.reduce(
            (total, item) => total + Number(item.spentBudget),
            0
          )
    };

    setBudgetData(payload);

    const spentCardData = customBudgetData.map(customBudgetItem => {
      return {
        subscribedId: customBudgetItem.id,
        userId: customBudgetItem.userId,
        cardId: customBudgetItem.providerId,
        price: customBudgetItem.spentBudget
      };
    });

    setSelectedDataBudget(spentCardData);
  }, [customBudgetData]);

  const unsubscribe = async customBudgetId => {
    try {
      await apiService.delete(`/custom-budgets/${customBudgetId}`);
      await getCustomBudgetInfo();
      toast.success("Uspešna odjava pretplate.");
    } catch (error) {
      console.log(error, "unsubscribe");
      toast.error("Neuspešna odjava pretplate.");
    }
  };

  const getDataForUnsubscribe = useCallback(
    async cardId => {
      try {
        const response = await apiService.get(
          `/custom-budgets/checkup?provider_id=${cardId}`
        );

        const customBudgetId = response.data.data.id;
        await unsubscribe(customBudgetId);
      } catch (error) {
        console.log(error, "getDataForUnsubscribe");
        toast.error("Neuspešna odjava pretplate");
      }
    },
    [selectedDataBudget]
  );

  return (
    <section className="custom-budget__body">
      <p className="custom-budget-body__intro">
        Draga {vocativeName || firstName}, u vidu poklona za 8. mart, priredili
        smo Vam sledeća iskustva koje možete izabrati.
      </p>

      <span className="custom-budget-body__budget">
        <span>
          <b>Ukupan Budžet:</b> {budgetData.totalBudget} <FlagCurrency />
        </span>
        <span>
          <b>Preostali Budžet:</b>{" "}
          {budgetData.totalBudget - budgetData.spentBudget} <FlagCurrency />
        </span>
      </span>

      <section className="custom-budget-body__cards">
        {/* For benefits */}
        {shouldShowMorePerks &&
          morePerksStaticData.map(card => {
            return (
              <March8Cards
                key={card.id}
                card={card}
                data={data}
                handleSelect={handleSelect}
                selectedDataBudget={selectedDataBudget}
                budgetData={budgetData}
                priceless={true}
                disabled={selectedDataBudget
                  .map(item => item.cardId)
                  .some(someItem =>
                    staticCardData.map(item => item.id).includes(someItem)
                  )}
                getDataForUnsubscribe={getDataForUnsubscribe}
              />
            );
          })}
        {/* For other users */}
        {staticCardData.map(card => {
          return (
            <March8Cards
              key={card.id}
              card={card}
              data={data}
              handleSelect={handleSelect}
              selectedDataBudget={selectedDataBudget}
              budgetData={budgetData}
              priceless={false}
              disabled={selectedDataBudget
                .map(item => item.cardId)
                .some(someItem =>
                  morePerksStaticData.map(item => item.id).includes(someItem)
                )}
              getDataForUnsubscribe={getDataForUnsubscribe}
            />
          );
        })}
      </section>
    </section>
  );
};
