import styled from "styled-components";

const March8Styles = styled.div`
  width: 100%;
  position: relative;

  .custom-budget__header {
    height: 50px;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #757575;

    .custom-budget__company-logo {
      border-radius: 50%;
    }

    h3 {
      width: 100%;
      position: absolute;
      text-align: center;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-top: 15px;
    }
  }

  .custom-budget__body {
    display: flex;
    flex-direction: column;
    padding: 20px;

    .custom-budget-body__intro {
      text-align: justify;
      font-size: 14px;
      color: #757575;
      font-weight: 500;
    }

    .custom-budget-body__budget {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      justify-content: space-evenly;
      border-radius: 16px;
      background: #fff;
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
      padding: 8px 10px 8px 10px;
      display: flex;
      padding: 10px;
      flex-direction: column;
      width: 250px;
      margin-top: 20px;
      align-items: flex-start;
      gap: 10px;

      & > span {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        color: #757575;
        gap: 5px;
      }
    }

    .custom-budget-body__cards {
      flex-wrap: wrap;
      margin-top: 20px;
      display: flex;
      justify-content: center;
      transition: border 0.5s ease-in;

      .benefitCardWrapper {
        border: 2px solid #757575;

        &:hover {
          border: 2px solid #0b75a6;
        }

        &--selected {
          border: 2px solid #0b75a6;
          background-color: #a1d8f280;
        }

        &--disabled {
          opacity: 0.7;
          cursor: not-allowed;
        }
      }

      .tokensContentChosen {
        font-size: 14px;
        font-weight: 500;
        color: #757575;
        display: flex;
        gap: 5px;
        align-items: center;

        button {
          padding: 7px 30px;
          border-radius: 4px;
          border: none;
          background: transparent;
          cursor: pointer;
          border: 0.5px solid #0b75a6;
          color: #0b75a6;
          font-size: 14px;
          font-weight: 500;
          transition: 0.5s ease-in;

          &:hover {
            background: #0b75a6;
            color: #fff;
          }

          &:disabled {
            cursor: not-allowed;
            pointer-events: none;
          }
        }
      }

      .tokensContent {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .input-tokens {
          padding: 10px;
          width: 120px;
          height: 35px;
          border: 1px solid #0b75a6;
          border-radius: 5px;
          text-align: left;
          font-size: 14px;
          font-weight: 500;
          color: #757575;

          &--disabled {
            cursor: not-allowed;
            pointer-events: none;
          }
        }

        button {
          padding: 7px 30px;
          border-radius: 4px;
          border: none;
          background: transparent;
          cursor: pointer;
          border: 0.5px solid #0b75a6;
          color: #0b75a6;
          font-size: 14px;
          font-weight: 500;
          transition: 0.5s ease-in;

          &:hover {
            background: #0b75a6;
            color: #fff;
          }

          &:disabled {
            cursor: not-allowed;
            pointer-events: none;
          }
        }
      }
    }
  }
`;

export default March8Styles;
