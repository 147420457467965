import React, { useRef, useState } from "react";
import BenefitCardStyles from "../../benefitCard/BenefitCardStyles";
import { truncate } from "../../../services/truncateString";
import { FlagCurrency } from "../../../assets/Icons";
import moment from "moment";

export const March8Cards = props => {
  const {
    card,
    data,
    handleSelect,
    selectedDataBudget,
    budgetData,
    priceless,
    disabled,
    getDataForUnsubscribe
  } = props;

  const budgetRef = useRef(null);
  const [inputValue, setInputValue] = useState("");

  const handleSelectCard = () => {
    const currentDate = moment();
    const deadline = moment("2025-03-15");

    if (currentDate.isAfter(deadline)) return;

    handleSelect({
      cardId: card.id,
      price: priceless ? card.price : inputValue,
      cardDescription: card.title,
      customProvider: priceless
    });
  };

  const debouncedChange = e => {
    const value = parseFloat(e.target.value);
    if (value < 0) return;

    if (value > budgetData.totalBudget - budgetData.spentBudget) {
      budgetRef.current.value = (
        budgetData.totalBudget - budgetData.spentBudget
      ).toString();
    } else {
      budgetRef.current.value = e.target.value;
    }

    setInputValue(budgetRef.current.value);
  };

  const selectedIds = selectedDataBudget.map(item => item.cardId);
  const selectedBudgetCard = selectedDataBudget.find(
    item => item.cardId === card.id
  );

  const handleUnsubScribe = cardId => {
    getDataForUnsubscribe(cardId);
  };

  return (
    <BenefitCardStyles id="benefitCard">
      <div
        className={`benefitCardWrapper ${
          selectedIds.includes(card.id) ? "benefitCardWrapper--selected" : ""
        }  ${disabled ? "benefitCardWrapper--disabled" : ""} `}
        style={{ height: "unset" }}
      >
        <div
          className="imgContainer"
          style={{ width: "100%", height: "184px" }}
        >
          <img
            src={card.image}
            alt="Benefiti Logo"
            className="benefitImage"
            crossOrigin="anonymous"
          />
        </div>
        <div className="underImageCardWrapper">
          <div className="topContent">
            <div className="providerImageOuterCircle">
              <div className="providerImageInnerCircle">
                <img
                  src="https://gazfduoumclmczxravnt.supabase.co/storage/v1/object/public/platform/demo/benefiti-logo.jpeg"
                  alt="Provider logo"
                  className="providerLogo"
                  crossOrigin="anonymous"
                />
              </div>
            </div>
            <div className="titleAndTokensContainer">
              <h2 className="title">{card.title}</h2>
              <div className="categoryTag">
                <p className="categoryText">{card.category}</p>
              </div>
            </div>
          </div>
          <div className="descriptionContainer">
            <p className="providerDescription">
              {truncate(card.description, 89)}
            </p>
          </div>
          <div className="locations-container">
            <p className="cities">{card.location}</p>
          </div>
          <div className="priceAncCapacityContainer">
            <div className="tokensContainer">
              <div
                style={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                {selectedIds.includes(card.id) ? (
                  <div className="tokensContentChosen">
                    {!priceless ? (
                      <>
                        <>
                          <p className="price">
                            <b>{selectedBudgetCard.price}</b>
                          </p>
                          <FlagCurrency />
                        </>
                        <>
                          <button onClick={() => handleUnsubScribe(card.id)}>
                            Odjavi pretplatu
                          </button>
                        </>
                      </>
                    ) : (
                      <>
                        <button onClick={() => handleUnsubScribe(card.id)}>
                          Odjavi pretplatu
                        </button>
                      </>
                    )}
                  </div>
                ) : (
                  <div className="tokensContent">
                    {!priceless && (
                      <input
                        type="number"
                        min="0"
                        max={budgetData.totalValue}
                        ref={budgetRef}
                        value={budgetRef.current?.value || ""}
                        onChange={debouncedChange}
                        className="input-tokens"
                        disabled={disabled}
                      />
                    )}
                    {priceless ? (
                      <>
                        {!selectedIds.some(item => item > 0) && (
                          <button onClick={handleSelectCard}>
                            Pretplati se
                          </button>
                        )}
                      </>
                    ) : (
                      <button
                        disabled={!inputValue || disabled}
                        onClick={handleSelectCard}
                      >
                        Pretplati se
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </BenefitCardStyles>
  );
};
